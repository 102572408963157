import { useState } from "react";
import "./App.css";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import CardForm from "./components/CardForm";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Xarrow from "react-xarrows";
import RelationMsg from "./components/RelationMsg";
function App() {
  const [inputList, setInputList] = useState([]);
  const [compRelation, setCompRelation] = useState([]);
  const [relation, setrelation] = useState({
    "1stComp": "",
    "2ndComp": "",
    "label": "",
  });
  const deleteForm = (item) => {
    

    setInputList(inputList.filter((res) => res.unique !== item.unique));
  };
  const onAddBtnClick = (event) => {
    setInputList(
      inputList.concat({ unique: Date.now(), id: inputList.length })
    );
  };
  const deleteFromArrowList = (id) => {
    setCompRelation(
      compRelation.filter((item) => item.Id1 !== id && item.Id2 !== id)
    );
  };
  const handleConnectComponentBtn = () => {
    const com1 = relation["1stComp"];
    const com2 = relation["2ndComp"];
    if (
      com1 < 0 ||
      com2 < 0 ||
      com1 >= inputList.length ||
      com2 >= inputList.length ||
      (relation["1stComp"]===relation["2ndComp"])
    ) {
      
      return;
    }
    let Id1 = "",
      Id2 = "";
    inputList.forEach((item, index) => {
      if (index.toString() === com1) Id1 = item.unique.toString();
      if (index.toString() === com2) Id2 = item.unique.toString();
    });
    if(Id1==="" || Id2==="")
      return
    setCompRelation(compRelation.concat({ Id1, Id2, msg: relation["label"] }));
    document.getElementById("closeModal").click();
  };
  const handleChangeComponent = (e) => {
    setrelation({ ...relation, [e.target.name]: e.target.value });
  };
  return (
    <div className="App">
      <h2 className="text-center">The canvas</h2>
      <ContextMenuTrigger id="contextmenu"  holdToDisplay={-1} >
        <div className="canvas">
          <DndProvider backend={HTML5Backend}>
            {inputList.map((item, index) => {
              return (
                <CardForm
                  deleteFromArrowList={deleteFromArrowList}
                  unique={item.unique}
                  index={index}
                  deleteForm={deleteForm}
                  key={item.unique}
                  id={item.id}
                  cardList={inputList}
                />
              );
            })}

            {compRelation.map((item, index) => {
              return (
                <Xarrow
                  key={index}
                  start={item.Id1}
                  end={item.Id2}
                  labels={{start: <RelationMsg msg={item.msg} /> }}
                />
              );
            })}
          </DndProvider>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id="contextmenu">
        <MenuItem className="menuItem" data={{ copy: "MI50" }} onClick={() => onAddBtnClick()}>
          <span>New Card</span>
        </MenuItem>
        <MenuItem className="menuItem" data={{ copy: "MI50" }}>
          <span data-bs-toggle="modal" data-bs-target="#exampleModal">
            Connect components
          </span>
        </MenuItem>
      </ContextMenu>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Connect Components
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="connectComponentFormBody">
                <div className="connectComponenForm">
                  <div className="row g-3 align-items-center my-3">
                    <div className="col-auto">
                      <label htmlFor="inputPassword6" className="col-form-label">
                        1st Card No.
                      </label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        name="1stComp"
                        
                        onChange={handleChangeComponent}
                        className="form-control"
                        aria-describedby="passwordHelpInline"
                      />
                    </div>
                  </div>
                </div>

                <div className="row g-3 align-items-center my-3">
                  <div className="col-auto">
                    <label htmlFor="inputPassword6" className="col-form-label">
                      2nd Card no.
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      name="2ndComp"
                      onChange={handleChangeComponent}
                      className="form-control"
                      aria-describedby="passwordHelpInline"
                    />
                  </div>
                </div>
                <div className="form-floating my-3">
                  <textarea
                    name="label"
                    className="form-control"
                    onChange={handleChangeComponent}
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                  ></textarea>
                  <label htmlFor="floatingTextarea">Message</label>
                </div>
              </div>

              
              {(parseInt(relation["1stComp"])<0 || parseInt(relation["1stComp"])>=inputList.length || parseInt(relation["2ndComp"])<0 || parseInt(relation["2ndComp"])>=inputList.length) && <p style={{color:"red"}}>Make sure card exists</p>}
            {parseInt(relation["1stComp"])===parseInt(relation["2ndComp"]) && <p style={{color:"red"}}>Self relation not possible</p>}
            </div>
            <div className="modal-footer">
              <button
                id="closeModal"
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleConnectComponentBtn}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
