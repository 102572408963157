import React from 'react'

export default function RelationMsg({msg}) {
  
    return (
        <div className="relationMsg p-2" id="msgBox">
            {msg}
        </div>
    )
}
